import React from "react";
import { graphql, navigate, PageProps } from "gatsby";
import ReactMarkdown from "react-markdown";

import SEO from "../components/SEO";
import NavigationScrollbar from "../components/NavigationScrollbar";

import { PaddingContainer } from "../components/Containers";
import { ProductHero } from "../components/Product";

import {
  GridContainer,
  Introduction,
  FeaturedProducts,
  FrequentlyAskedQuestions,
} from "../components/Home";

const EnglishHomePage: React.FC<PageProps> = ({ location, data }) => {
  const { home } = data as any;
  const { SEO: SEOProps, introduction, heroTitle, heroImage } = home;
  const { title, description } = SEOProps;

  const navigateToSlidingTrays = () => navigate("schuiflades");

  return (
    <>
      <SEO
        title={title}
        description={description}
        lang="en"
        pathName={location.pathname}
      />
      <NavigationScrollbar />
      {heroTitle && heroImage && (
        <ProductHero
          title={heroTitle}
          imageData={heroImage.localFile}
          buttonText="Shop"
          onButtonClick={navigateToSlidingTrays}
        />
      )}
      <PaddingContainer>
        <GridContainer>
          <Introduction>
            <ReactMarkdown>{introduction}</ReactMarkdown>
          </Introduction>
          <FeaturedProducts title="Popular items" />
          <FrequentlyAskedQuestions
            title="Frequently Asked Questions"
            language="en"
          />
        </GridContainer>
      </PaddingContainer>
    </>
  );
};

export const englishHomePageQuery = graphql`
  query EnglishHomePageQuery {
    home: strapiHome {
      SEO {
        title: englishTitle
        description: englishDescription
      }
      introduction: englishIntroduction
      heroTitle: englishHeroTitle
      heroImage {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`;

export default EnglishHomePage;
